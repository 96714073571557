<script setup lang="ts">
import { useSound } from '@vueuse/sound'
import click from '~/assets/sounds/click.mp3'
const { play } = useSound(click)

import type {CSSProperties} from "@vue/runtime-dom";

export interface RadioButtonProperties {
  name: string
  modelValue: string
  value: string
  data: { id: any, name: string, disabled?: boolean }
  prefix: string // shirt_
  checked?: boolean
  style?: CSSProperties
  shadowOffset?: number
}
const props = defineProps<RadioButtonProperties>()
const emit = defineEmits(['update:modelValue'])

const isChecked = computed(() => props.modelValue === props.value)
// console.log('radio', `${props.prefix}${props.data.name}`)

const onClick = (event: MouseEvent) => {
    play()
}

</script>

<template>
  <div class="radio-image" :style="style">
    <label>
      <input type="radio" :name="name"
             :value="value"
             @change="$emit('update:modelValue', $event.target.value)"
             @click="onClick"
             class="radio" :disabled="data.disabled"
             :checked="checked">
      <span class="glow-container" :style="style"> <span class="glow-circle"></span> </span>
      <CircledImage :url="$getImage(`${prefix}${data.name}.png`)" :shadow="!isChecked && !data.disabled" :alt="`${prefix}${data.name}`" :image-style="style" :shadow-offset="shadowOffset" />
    </label>
  </div>
</template>

<style lang="scss" scoped>
  .radio-image {

    label {
      position: relative;

      .glow-container {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /* IMAGE STYLES */
      input.radio + span.glow-container + div.circled-image {
        cursor: pointer;
      }

      input.radio:disabled + span.glow-container + div.circled-image {
        cursor: default;
        opacity: 0.5;
      }

      input.radio:checked + span.glow-container + div.circled-image {
        cursor: default;
      }
    }
  }

  /* HIDE RADIO */
  input.radio {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  @keyframes pulse {
    0% { box-shadow:0 0 10px yellow, inset 0 0 15px yellow; }
    50% { box-shadow:0 0 20px yellow, inset 0 0 30px yellow; }
    100% { box-shadow:0 0 10px yellow, inset 0 0 15px yellow; }
  }

  /* CHECKED STYLES */
  input.radio:checked + .glow-container {
    span.glow-circle {
      opacity: 1;
      background-color: transparent;
      width: 90%;
      height: 90%;
      border-radius: 100%;
      box-shadow: 0 0 10px yellow, inset 0 0 15px yellow;
      animation: pulse 3s linear 1s infinite;
    }
  }

  input.radio:disabled + .glow-container {
    span.glow-circle {
      opacity: 0;
    }
  }


</style>